<template>
  <div class="ming">
    <div class="alert-modal" v-show="showModal1">
      <div class="mask"></div>
      <div class="cont">
        <div class="title">
          {{isSwitch1 ? 'Stake LP' : 'Unstake LP'}} Tokens
          <b-img class="close-btn" @click="onToggleModal" :src="require('../assets/images/close.png')" width="25"/>
        </div>
        <div class="switch" :class="{'done': isSwitch1}" @click="onSwitch1">
          <div class="val">{{ isSwitch1 ? 'Stake LP' : 'Unstake LP' }}</div>
          <div class="btn"></div>
        </div>
        <div class="info">
          <div class="flex">
            <span class="sp1">{{isSwitch1 ? 'Stake' : 'Unstake'}}:</span>
            <input class="ipt" v-model="val1" />
          </div>
          <div class="flex">
            <span class="sp1">{{isSwitch1 ? 'Balance' : 'Staked'}}:</span>
            <span class="sp2">{{isSwitch1 ? uInfo.balance.toFixed(4,1) : uInfo.staked.toFixed(4,1)}}</span>
            <span class="sp3" @click="doMax">Max</span>
          </div>
        </div>
        <div class="action">
          <div class="btn" @click="doCancel">Cancel</div>
          <div class="btn" @click="doConfirm">{{btnText}}</div>
        </div>
      </div>
    </div>

    <b-container v-waypoint="{active: !aniMap.show, callback: (e) => cb(e, 0),  options}">
      <transition enter-active-class="animated delay-500 fadeInUp">
        <div class="text-center tit" v-if="aniMap.show">
          <b-img :src="require('../assets/images/farm/tit.png')"/>
        </div>
      </transition>
      <transition enter-active-class="animated delay-500 fadeInUp">
        <div class="text-center sub-tit pointer" @click="goToGuide" v-if="aniMap.show">
          Read the guidance for liquidity mining
          <b-img :src="require('../assets/images/farm/arrow.png')"/>
        </div>
      </transition>
      <!-- fram -->
      <transition enter-active-class="animated delay-500 fadeInUp">
        <div class="main" v-show="aniMap.show">
          <!-- inner farm pc -->
          <div class="farm-wrapper farm1 d-none d-md-block">
            <div class="farm-tit">Berry Farm</div>
            <div class="farm-table">
              
              <!-- bry staking -->
              <div class="tr">
                <!-- <div class="tip">Please claim your rewards before 05-13 00:00 SGT!!</div> -->
                <div class="c1 td" :cols="3">
                  <div class="img-box">
                    <b-img width="60" height="60" :src="require('../assets/images/farm/BRY.png')"/>
                  </div>
                  <span class="t0">BRY Staking</span>
                </div>
                <div class="c2 td" :cols="2">
                  <div class="t1">Earned</div>
                  <div class="t2">{{bryInfo.earned.toFixed(4,1)}}</div>
                </div>
                <div class="c3 td" :cols="2">
                  <div class="t1">APR</div>
                  <div class="t2">{{bryInfo.apy}}%</div>
                </div>
                <div class="c4 td d-none d-md-block" :cols="3">
                  <div class="t1">Total Liquidity</div>
                  <div class="t2">{{bryInfo.totalStaked.toFixed(4,1) | kFormat}}</div>
                </div>
                <div class="c5 td" :cols="2">
                  <div class="pointer d-flex align-items-center" @click="onExpand1(100)">
                    <a class="t3 d-none d-md-block" href="javascript:;">Details</a>
                    <b-img v-if="100 !== toggleMap1[100]" width="16" height="16" :src="require('../assets/images/arrow-down.png')"/>
                    <b-img v-else width="16" height="16" :src="require('../assets/images/arrow-up.png')"/>
                  </div>
                </div>
                <div class="detail" v-if="100 === toggleMap1[100]">
                  <div class="links">
                    <div class="link">
                      <a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xf859bf77cbe8699013d6dbc7c2b926aaf307f830" target="_blank">Get BRY</a> >
                    </div>
                    <div class="link">
                      <a href="https://bscscan.com/token/0xf859bf77cbe8699013d6dbc7c2b926aaf307f830" target="_blank">View on BscScan</a> >
                    </div>
                  </div>
                  <div class="card card1">
                    <div class="card-tit">BRY Earned</div>
                    <div class="card-main">
                      <div class="num">{{bryInfo.earned.toFixed(4,1)}}</div>
                      <b-button @click="doHarvest(100)" class="btn" pill>Harvest</b-button>
                    </div>
                  </div>
                  <div class="card card2">
                    <div class="card-tit">START FARMING</div>
                    <div v-if="!isConnect" class="card-main d-flex justify-content-center">
                      <b-button @click="handleConnect" class="btn" pill>UNLOCK WALLET</b-button>
                    </div>
                    <div v-else class="card-main d-flex justify-content-between align-items-center">
                      <div class="num">{{bryInfo.staked.toFixed(4,1)}}</div>
                      <b-button-group>
                        <b-button @click="showDialog(100, false)" class="btn-sub">-</b-button>
                        <b-button @click="showDialog(100, true)" class="btn-add">+</b-button>
                      </b-button-group>
                    </div>
                  </div>
                </div>
              </div>

              <!-- lp staking -->
              <div class="tr" v-for="p in pools" :key="p.id" >
                <div class="tip" v-if="p.isEW">The pool has stopped，please remove your LP.</div>
                <div class="c1 td" :cols="3">
                  <div class="img-box">
                    <b-img width="60" height="60" :src="require('../assets/images/farm/BRY.png')"/>
                    <span :class="p.bnb ? 'bnb-span' : 'usdt-span'"></span>
                  </div>
                  <span class="t0">{{p.name}}</span>
                </div>
                <div class="c2 td" :cols="2">
                  <div class="t1">Earned</div>
                  <div class="t2">{{p.isEW ? 0 : user[p.id].earned.toFixed(4,1)}}</div>
                </div>
                <div class="c3 td" :cols="2">
                  <div class="t1">APR</div>
                  <div class="t2">{{p.isEW ? 0 : p.apy}}%</div>
                </div>
                <div class="c4 td d-none d-md-block" :cols="3">
                  <div class="t1">Total Liquidity</div>
                  <div class="t2">{{p.totalStakedLP.toFixed(4,1) | kFormat}}</div>
                </div>
                <div class="c5 td" :cols="2">
                  <div class="pointer d-flex align-items-center" @click="onExpand1(p.id)">
                    <a class="t3 d-none d-md-block" href="javascript:;">Details</a>
                    <b-img v-if="p.id !== toggleMap1[p.id]" width="16" height="16" :src="require('../assets/images/arrow-down.png')"/>
                    <b-img v-else width="16" height="16" :src="require('../assets/images/arrow-up.png')"/>
                  </div>
                </div>
                <div class="detail" v-if="p.id === toggleMap1[p.id]">
                  <div class="links">
                    <div class="link">
                      <a :href="p.pancakeUrl" target="_blank">Get {{p.name}}</a> >
                    </div>
                    <div class="link">
                      <a :href="p.bscScanUrl" target="_blank">View on BscScan</a> >
                    </div>
                  </div>
                  <div class="card card1">
                    <div class="card-tit">BRY Earned</div>
                    <div class="card-main">
                      <div class="num">{{p.isEW ? 0 : user[p.id].earned.toFixed(4,1)}}</div>
                      <b-button @click="doHarvest(p.id)" class="btn" pill>Harvest</b-button>
                    </div>
                  </div>
                  <div class="card card2">
                    <div class="card-tit">START FARMING</div>
                    <div v-if="!isConnect" class="card-main d-flex justify-content-center">
                      <b-button @click="handleConnect" class="btn" pill>UNLOCK WALLET</b-button>
                    </div>
                    <div v-else class="card-main d-flex justify-content-between align-items-center">
                      <div class="num">{{user[p.id].staked.toFixed(4,1)}}</div>
                      <b-button-group>
                        <b-button @click="showDialog(p.id, false)" class="btn-sub">-</b-button>
                        <b-button @click="showDialog(p.id, true)" class="btn-add">+</b-button>
                      </b-button-group>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <!-- inner farm h5 -->
          <div class="m-farm1 m-farm2 d-md-none">
            <div class="farm-tit">Berry Farm</div>
            <div class="farm-list">

              <!-- bry staking -->
              <div class="farm-list-item">
                <!-- <div class="tip">Please claim your rewards before 05-13 00:00 SGT!!!</div> -->
                <div class="item1" @click="onMobileExpand(100)">
                  <div class="i1">
                    <div class="img-box">
                      <b-img width="30" height="30" :src="require('../assets/images/farm/BRY.png')"/>
                      <!-- <span></span> -->
                    </div>
                    <div class="txt">BRY STAKING</div>
                  </div>
                  <div class="i3">
                    <div class="t1">Earned</div>
                    <div class="t2">{{bryInfo.earned.toFixed(4,1)}}</div>
                  </div>
                  <div class="i3">
                    <div class="t1">APR</div>
                    <div class="t2">{{bryInfo.apy}}%</div>
                  </div>
                  <div class="i4">
                    <b-img v-if="100 === mobileExpandMap[100]" width="16" height="16" :src="require('../assets/images/arrow-up.png')"/>
                    <b-img v-else width="16" height="16" :src="require('../assets/images/arrow-down.png')"/>
                  </div>
                </div>
                <div v-show="100 === mobileExpandMap[100]">
                  <div class="item2">
                    <div class="txt">
                      <div>BRY Earned</div>
                      <div class="num">{{bryInfo.earned.toFixed(4,1)}}</div>
                    </div>
                    <b-button @click="doHarvest(100)" pill>Harvest</b-button>
                  </div>
                  <div class="item3">
                    <div v-if="!isConnect" class="unlock-1">
                      <div class="tit">START FARMING</div>
                      <b-button @click="handleConnect" pill>UNLOCK WALLET</b-button>
                    </div>
                    <div v-else class="lock d-flex justify-content-between align-items-center">
                      <div class="txt">
                        <div>STAKED</div>
                        <div class="num">{{bryInfo.staked.toFixed(4,1)}}</div>
                      </div>
                      <b-button-group>
                        <b-button @click="showDialog(100, false)" class="btn-sub">-</b-button>
                        <b-button @click="showDialog(100, true)" class="btn-add">+</b-button>
                      </b-button-group>
                    </div>
                  </div>
                  <div class="item4">
                    <div class="ii">
                      <span>Deposit:</span>
                      <a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xf859bf77cbe8699013d6dbc7c2b926aaf307f830" target="_blank">
                        <span>BRY</span>
                        <b-img width="16" height="16" :src="require('../assets/images/arrow-r.png')"/>
                      </a>
                    </div>
                    <div class="ii">
                      <span>Total Liquidity :</span><span>{{bryInfo.totalStaked.toFixed(4,1) | kFormat}}</span>
                    </div>
                    <div class="ii">
                      <a href="https://bscscan.com/token/0xf859bf77cbe8699013d6dbc7c2b926aaf307f830" target="_blank">View on BscScan ></a>
                    </div>
                  </div>
                </div>
              </div>

              <!-- lp staking -->
              <div class="farm-list-item" v-for="p in pools" :key="p.id">
                <div class="tip" v-if="p.isEW">The pool has stopped，please remove your LP.</div>
                <div class="item1" @click="onMobileExpand(p.id)">
                  <div class="i1">
                    <div class="img-box">
                      <b-img width="30" height="30" :src="require('../assets/images/farm/BRY.png')"/>
                      <span></span>
                    </div>
                    <div class="txt">{{p.name}}</div>
                  </div>
                  <div class="i3">
                    <div class="t1">Earned</div>
                    <div class="t2">{{p.isEW ? 0 : user[p.id].earned.toFixed(4,1)}}</div>
                  </div>
                  <div class="i3">
                    <div class="t1">APR</div>
                    <div class="t2">{{p.isEW ? 0 : p.apy}}%</div>
                  </div>
                  <div class="i4">
                    <b-img v-if="p.id === mobileExpandMap[p.id]" width="16" height="16" :src="require('../assets/images/arrow-up.png')"/>
                    <b-img v-else width="16" height="16" :src="require('../assets/images/arrow-down.png')"/>
                  </div>
                </div>
                <div v-show="p.id === mobileExpandMap[p.id]">
                  <div class="item2">
                    <div class="txt">
                      <div>BRY Earned</div>
                      <div class="num">{{p.isEW ? 0 : user[p.id].earned.toFixed(4,1)}}</div>
                    </div>
                    <b-button @click="doHarvest(p.id)" pill>Harvest</b-button>
                  </div>
                  <div class="item3">
                    <div v-if="!isConnect" class="unlock-1">
                      <div class="tit">START FARMING</div>
                      <b-button @click="handleConnect" pill>UNLOCK WALLET</b-button>
                    </div>
                    <div v-else class="lock d-flex justify-content-between align-items-center">
                      <div class="txt">
                        <div>STAKED</div>
                        <div class="num">{{user[p.id].staked.toFixed(4,1)}}</div>
                      </div>
                      <b-button-group>
                        <b-button @click="showDialog(p.id, false)" class="btn-sub">-</b-button>
                        <b-button @click="showDialog(p.id, true)" class="btn-add">+</b-button>
                      </b-button-group>
                    </div>
                  </div>
                  <div class="item4">
                    <div class="ii">
                      <span>Deposit:</span>
                      <a :href="p.pancakeUrl" target="_blank">
                        <span>{{p.name}}</span>
                        <b-img width="16" height="16" :src="require('../assets/images/arrow-r.png')"/>
                      </a>
                    </div>
                    <div class="ii">
                      <span>Total Liquidity :</span><span>{{p.totalStakedLP.toFixed(4,1) | kFormat}}</span>
                    </div>
                    <div class="ii">
                      <a :href="p.bscScanUrl" target="_blank">View on BscScan ></a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <!-- cooperation pc -->
          <div class="farm-wrapper farm2 d-none d-md-block">
            <div class="farm-tit">Partner Farm</div>
            <div class="farm-table">
              <div class="tr thead">
                <div class="td c1">Pools</div>
                <div class="td c2">Earn</div>
                <div class="td c3">APR</div>
                <div class="td c4">Farm</div>
                <div class="td c5"></div>
              </div>
              <div class="tr" v-for="f in coFarmers" :key="f.farm">
                <div class="c1 td">
                  <div class="img-box">
                    <b-img width="60" height="60" :src="require('../assets/images/farm/BRY.png')"/>
                    <span :class="f.bnb ? 'bnb-span' : 'usdt-span'"></span>
                  </div>
                  <span class="t0">{{f.name}}</span>
                </div>
                <div class="c2 td">
                  <div class="img-box">
                    <b-img width="60" height="60" :src="require('../assets/images/farm/' + f.img)"/>
                  </div>
                  <span class="t1">{{f.earn}}</span>
                </div>
                <div class="c3 td">
                  <div class="t2">{{f.apy}}%</div>
                </div>
                <div class="c4 td">
                  <div class="t3">{{f.farm}}</div>
                </div>
                <div class="c5 td">
                  <b-button @click="goFarm(f.url)" pill class="btn">Go Farming ></b-button>
                </div>
              </div>
            </div>
          </div>
          <!-- cooperation h5 -->
          <div class="m-farm2 d-md-none">
            <div class="farm-tit">Partner Farm</div>
            <div class="farm-list">
              <div class="farm-list-item" v-for="f in coFarmers" :key="f.farm">
                <div class="item1">
                  <div class="i1">
                    <div class="img-box">
                      <b-img width="30" height="30" :src="require('../assets/images/farm/BRY.png')"/>
                      <span :class="f.bnb ? 'bnb-span' : 'usdt-span'"></span>
                    </div>
                    <div class="txt">{{f.name}}</div>
                  </div>
                  <div class="i2">
                    <div class="img-box">
                      <b-img width="30" height="30" :src="require('../assets/images/farm/' + f.img)"/>
                    </div>
                    <div class="txt">{{f.earn}}</div>
                  </div>
                  <div class="i3">
                    <div class="t1">APR</div>
                    <div class="t2">{{f.apy}}%</div>
                  </div>
                </div>
                <div class="item2">
                  <div class="txt">{{f.farm}}</div>
                  <b-button @click="goFarm(f.url)" pill>GO FARMING ></b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </b-container>
  </div>
</template>

<script>

import constants from '../constants/index'
import api from '../api/index'
import BN from 'bignumber.js'
import axios from 'axios'

export default {
  name: 'farm',
  components: {},
  data() {
    return {
      showModal1: false,
      isSwitch1: true,
      val1: 0,

      poolIndex: 0,
      btnText: 'Confirm',
      options: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 1]
      },
      aniMap: {
        show: false,
      },
      toggleMap1: {},
      mobileExpandMap: {},

      coFarmers: [
        // {name: 'BRY-BNB LP', earn: 'CAKE', apy: '0', farm: 'PancakeSwap', url: 'https://pancakeswap.finance/farms', img: 'CAKE.png', bnb: true},
        {name: 'BRY-USDT LP', earn: 'MDX', apy: '87.43', farm: 'MDEX', url: 'https://mdex.com/#/pool/liquidity/mdx/80', img: 'MDX.webp', bnb: false},
        // {name: 'BRY-BNB LP', earn: 'ALPACA', apy: '5.21', farm: 'AlpacaFinance', url: 'https://app.alpacafinance.org/farm/pancake-swap/pool/bry-bnb/-/open?leverage=1.75&configKey=bry-bnb', img: 'ALPACA.png', bnb: true},
        // {name: 'BRY-BNB LP', earn: 'AUTO', apy: '5.68', farm: 'AutoFarm', url: 'https://autofarm.network/bsc/286/', img: 'AUTO.png', bnb: true},
      ]
    }
  },
  filters: {
    kFormat(value) {
      if (!value) return '';
      var str = '' + value;
      var intPart = Number(value).toFixed(0);
      var pointPart = str.substring(str.lastIndexOf('.') === -1 ? str.length : str.lastIndexOf('.'));
      var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      return intPartFormat + pointPart;
    }
  },
  computed: {
    isConnect() {
      return this.$store.state.login;
    },
    pools() {
      return this.$store.state.pools
    },
    userStaked() {
      return this.$store.state.user[this.poolIndex].staked
    },
    userBalance() {
      return this.$store.state.user[this.poolIndex].balance
    },
    user() {
      return this.$store.state.user
    },
    bryInfo() {
      return this.$store.state.bryStake
    },
    uInfo() {
      let ui = this.poolIndex == 100 ? this.bryInfo : this.user[this.poolIndex];
      return ui;
    },
    pInfo() {
      let pi = this.poolIndex == 100 ? {lpAddr: constants.BRY_TOKEN} : this.pools[this.poolIndex];
      return pi;
    },
    tInfo() {
      let target = this.poolIndex == 100 ? constants.BRY_STAKE : constants.POOLS[this.poolIndex].CHEF;
      return target;
    }
  },
  methods: {
    handleConnect() {
      this.$store.commit('modalConnect');
    },
    cb({going, direction}, idx) {
      if (direction === 'bottom') return;
      // console.log(this.$waypointMap.GOING_IN, going)
      if (this.$waypointMap.GOING_IN === going) {
        this.aniMap[`show${idx ? idx : ''}`] = true
      } else {
        this.aniMap[`show${idx ? idx : ''}`] = false

      }
    },
    onMobileExpand(idx) {
      const map = {...this.mobileExpandMap}
      map[idx] = map[idx]>=0? -1:idx
      this.mobileExpandMap = {...map}
    },
    onExpand1(idx) {
      const map = {...this.toggleMap1}
      map[idx] = map[idx]>=0? -1:idx
      this.toggleMap1 = {...map}
    },
    onSwitch1() {
      this.isSwitch1 = !this.isSwitch1
      this.val1 = 0
    },
    onToggleModal() {
      this.showModal1 = !this.showModal1
    },
    goToGuide() {
      window.open('https://docs.berrydata.co/whitepaper/guidance-for-liquidity-mining', '_blank');
    },
    goFarm(url) {
      window.open(url, '_blank');
    },
    showSuccess(info, hash) {
      let self = this;
      let url = 'https://bscscan.com/tx/' + hash;
      let msg = info + ', View On BscScan.';
      self.$toast.open({
        message: msg,
        type: 'success',
        onClick: function () {
          window.open(url, '_blank');
        }
      });
    },
    showError(e) {
      this.$toast.error(e);
    },
    async doHarvest(pid) {
      let self = this;
      if (!self.$store.state.login) {
        self.showError('Please connect wallet');
        return;
      }

      let pi = pid === 100 ? {} : self.pools[pid];
      if (pi.isEW) {
        self.showError("Cannot harvest earnings at the moment.");
        return;
      }

      let hash = undefined;
      try {
        hash = await api.harvest(pid, self.$store.state.address);
        self.showSuccess('Harvest Succeess', hash);
      } catch (e) {
        console.error(e);
      }
    },
    async doConfirm() {
      let self = this;

      if (self.btnText === 'Confirm' || Number(self.val1) === 0) {
        self.onToggleModal()
        return;
      }

      let hash = undefined;
      try {
        if (self.btnText === 'Approve') {
          if (self.pInfo.isEW) {
            self.showError("Cannot harvest earnings at the moment.");
            self.onToggleModal()
            return;
          }
          
          hash = await api.approve(self.tInfo, self.pInfo.lpAddr, new BN(self.val1), self.$store.state.address);
          self.showSuccess('Approve Succeess', hash);
        }

        if (self.btnText === 'Stake') {
          if (self.pInfo.closing) {
            self.showError("BRY LP V1 staking is closing");
            self.onToggleModal();
            return;
          }
          if (self.pInfo.isEW) {
            self.showError("Cannot harvest earnings at the moment.");
            self.onToggleModal()
            return;
          }

          hash = await api.stake(self.poolIndex, self.val1, self.pInfo.lpAddr, self.$store.state.address);
          self.showSuccess('Stake Succeess', hash);
        }

        if (self.btnText === 'Unstake') {
          hash = await api.unstake(self.poolIndex, self.val1, self.pInfo.lpAddr, self.$store.state.address);
          self.showSuccess('Unstake Succeess', hash);
        }

        if (hash) {
          let timer = setInterval(async function() {
            let success = await api.checkTransaction(hash);
            if (success) {
              clearInterval(timer);
              await self.checkBtnText();
            }
            // TODO if failed?
          }, 1000);
        } else {
          self.onToggleModal()
        }
      } catch(e) {
        console.error(e);
        self.$toast.warning('Network error, please try again.');
        self.onToggleModal()
      }
    },


    async checkBtnText() {
      if (this.btnText === 'Approve') {
        let self = this;
        if (!self.$store.state.login) {
          self.showError('Please connect wallet');
          return; // shoudl not occur
        }

        let _address = self.$store.state.address;
        let isAllowed = await api.checkAllowance(self.pInfo.lpAddr, _address, self.tInfo, new BN(self.val1));
        self.btnText = isAllowed ? 'Stake' : 'Approve';
        if (self.btnText === 'Approve') {
          self.onToggleModal();
        }
      } else {
        this.onToggleModal();
      }
    },

    doCancel() {
      this.onToggleModal()
    },

    doMax() {
      if (this.isSwitch1) {
        this.val1 = Number(this.uInfo.balance.toFixed(6,1));
      } else {
        this.val1 = Number(this.uInfo.staked.toFixed(6,1));
      }
    },

    showDialog(pid, sw) {
      this.poolIndex = pid;
      this.val1 = 0;
      this.isSwitch1 = sw;
      this.onToggleModal()
    }
  },

  async created() {
    try {
      // let ret = await axios.get('https://api.berrydata.co/partner/apr');
      let ret = await axios.get('https://gateway.mdex.cc/v2/mingpool/lps?mdex_chainid=56')
      ret = ret.data.result;
      const key = 'USDT\/BRY';
      let apr = 0;
      for (let i = 0; i < ret.length; ++i) {
        if (ret[i].pool_name === key) {
          apr =(Number(ret[i].pool_apy) * 365).toFixed(2);
        }
      }
      this.coFarmers[0].apy = apr;
      // this.coFarmers[0].apy = ret['pancake'];
      // this.coFarmers[1].apy = ret['mdex'];
      // this.coFarmers[2].apy = ret['alpaca'];
      // this.coFarmers[3].apy = ret['autofarm'];
    } catch (e) {
      console.error(e);
    }
  },
  watch: {
    async val1(val, oldval) {
      let self = this;
      if (val === '')
      {
        return;
      }
      if (isNaN(val) || Number(val) < 0)
      {
        self.val1 = 0
        self.showError('Invalid input amount');
      }
      if (self.isSwitch1)
      {
        if (self.uInfo.balance.isLessThan(Number(val))) {
          self.showError('Input stake amount is not insufficient');
          self.val1 = oldval;
        }
        if (!self.$store.state.login) {
          self.showError('Please connect wallet');
          return;
        }
        let _address = self.$store.state.address;
        let isAllowed = await api.checkAllowance(self.pInfo.lpAddr, _address, self.tInfo, new BN(self.val1));
        self.btnText = isAllowed ? 'Stake' : 'Approve';
      }
      else
      {
        if (self.uInfo.staked.isLessThan(Number(val))) {
          self.val1 = oldval;
          self.showError('Input unstake amount is not insufficient');
        }
        self.btnText = 'Unstake';
      }
    },

  }
}
</script>
<style lang="less">
@color0: #3C103A;
@color1: #880D85;
@color2: #40153E;
@color3: #C695BC;
@color4: #f1e7f0;
.ming {
  background: #FFFAFF;
  padding: 60px;

  .tit {
    margin-bottom: 10px;
  }

  .sub-tit {
    color: @color1;
    margin-bottom: 30px;

    img {
      margin-left: 5px;
    }
  }

  .tit2 {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .pointer {
    cursor: pointer;
  }
  .main {
    margin: 0 -15px;
  }
  .farm-wrapper {
    .farm-tit {
      font-size: 30px;
      line-height: 42px;
      margin-bottom: 16px;
      color: @color2;
      font-weight: bold;
    }

    .farm-table {
      padding: 10px 50px 50px;
      box-shadow: 0 0 10px @color3;
      border-radius: 14px;
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 5px;
        border-radius: 14px;
        background-color: @color1;
      }
      .tr {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid @color3;
        padding: 30px 10px 40px;
        align-items: center;
        position: relative;
        .tip {
          position: absolute;
          top: 8px;
          left: 105px;
          color: rgb(240, 7, 28);
        }
      }
    }
  }

  .farm1 {

    .tr {font-weight: bold; text-align: center; padding: 0 15px;
      &:last-of-type {
        border-bottom: none;
      }
    }
    .td {display: inline-flex; flex-direction: column; align-items: center; justify-content: center;}
    .c1 {flex: 0 0 30%;}
    .c2 {flex: 0 0 15%;}
    .c3 {flex: 0 0 15%;}
    .c4 {flex: 0 0 25%;}
    .c5 {flex: 0 0 15%;}
    .c1 { flex-direction: row; justify-content: flex-start;}
    .c5 { flex-direction: row;}
    .img-box {
      position: relative;

      .bnb-span {
            position: absolute;
            bottom: 0;
            right: -10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: url('../assets/images/farm/i1.png') center center;
            background-size: 100% 100%;
          }

          .usdt-span {
            position: absolute;
            bottom: 0;
            right: -10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: url('../assets/images/farm/i3.png') center center;
            background-size: 100% 100%;
          }

      // span {
      //   position: absolute;
      //   bottom: 0;
      //   right: -10px;
      //   width: 30px;
      //   height: 30px;
      //   border-radius: 50%;
      //   background: url('../assets/images/farm/i1.png') center center;
      //   background-size: 100% 100%;
      //   // background-color: #778CA0;
      // }
    }

    .t0 {
      margin-left: 35px;
      font-size: 20px;
      color: #3C103A;
      font-weight: bold;
    }

    .t1, .t2 {
      line-height: 34px;
      font-size: 20px;
    }

    .t1 {
      color: #3C103A;
    }

    .t2 {
      color: @color1;
    }

    .t3 {
      color: @color1;
      font-size: 20px;
      font-weight: normal;
      margin-right: 14px;
    }
    .detail {
      margin-top: 40px;
      padding: 30px 30px 30px 70px;
      background-color: @color4;
      opacity: .8;
      border-radius: 8px;
      display: flex;
      flex: 0 0 100%;
      align-items: center;
      .links {
        margin-right: 60px;
        .link {
          color: @color1;
        }
        a {
          color: @color1;
          font-weight: normal;
          font-size: 20px;
          line-height: 33px;
          margin-bottom: 20px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .card {
        width: 340px;
        height: 150px;
        padding: 20px 30px;
        box-sizing: border-box;
        border: 1px solid #A9A2A9;
        box-shadow: 0 0 10px #666;
        margin-right: 20px;
        border-radius: 14px;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        &:last-of-type {
          margin-right: 0;
        }
        &-tit {
          font-size: 18px;
          color: @color0;
          text-align: left;
        }
      }
      .card1 {
        .card-main {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .num {
          font-size: 20px;
          color: @color0;
        }
        .btn {
          width: 130px;
          height: 62px;
          font-size: 18px;
          background-color: @color1;
          border: none;
          outline: none;
        }
      }
      .card2 {
        .card-main {
          display: flex;
          justify-content: center;
        }
        .num {
          font-size: 20px;
          color: @color0;
        }
        .btn {
          width: 220px;
          height: 62px;
          font-size: 18px;
          background-color: @color1;
          border: none;
          outline: none;
        }
        .btn-add, .btn-sub {
          width: 50px;
          height: 50px;
          border-radius: 12px;
          &:disabled {
            background-color: #B79AB6;
          }
        }
        .btn-add {
          margin-left: 10px;
        }
      }
    }
  }

  .farm2 {
    margin-top: 40px;
    .farm-table {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
      .tr {
        margin-right: 50px;
        margin-left: 50px;
        padding: 40px 0;
      }
      .td {
        display: inline-flex;
        align-items: center;
        font-size: 20px;
        * {
          font-weight: bold;
          font-size: 20px;
        }
      }
      .thead {
        margin: 0;
        padding: 0;
        padding-left: 65px;
        padding-right: 65px;
        height: 80px;
        background-color: @color4;
        .td {
          font-size: 20px;
          font-weight: bold;
        }
      }
      // partner apr
      .c1 { 
        flex: 0 0  27%;
      
        .img-box {
          position: relative;

          .bnb-span {
            position: absolute;
            bottom: 0;
            right: -10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: url('../assets/images/farm/i1.png') center center;
            background-size: 100% 100%;
          }

          .usdt-span {
            position: absolute;
            bottom: 0;
            right: -10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: url('../assets/images/farm/i3.png') center center;
            background-size: 100% 100%;
          }

          // span {
          //   position: absolute;
          //   bottom: 0;
          //   right: -10px;
          //   width: 30px;
          //   height: 30px;
          //   border-radius: 50%;
          //   background: url('../assets/images/farm/i1.png') center center;
          //   background-size: 100% 100%;
          // }
        }
      }
      .c2 { flex: 0 0  20%;}
      .c3 { flex: 0 0  17%;}
      .c4 { flex: 0 0  18%;}
      .c5 { flex: 0 0  18%;}
      .c3, .c4 {
        justify-content: center;
      }
      .c5 {
        justify-content: flex-end;
      }
      .t0 {
        margin-left: 15px;
      }
      .t1 {
        margin-left: 10px;
      }
      .t0, .t1, .t3 {
        color: @color0;
      }
      .t2 {
        color: @color1;
      }
      .t3 {
        font-size: 20px;
      }
      .btn {
        width: 175px;
        height: 62px;
        background-color: @color1;
        font-size: 18px;
        font-weight: normal;
        border: none;
      }
    }
  }

  .alert-modal {
    .mask {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0 ,0, .5);
      z-index: 9;
    }
    .cont {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
      width: 406px;
      max-width: 100%;
      height: 420px;
      padding: 40px;
      border-radius: 20px;
      box-sizing: border-box;
      background-color: #fff;
      .title {
        font-size: 20px;
        position: relative;
        font-weight: bold;
        color: #880D85;
        text-align: center;
      }

      .close-btn {
        position: absolute;
        top: -15px;
        right: -5px;
        cursor: pointer;
      }

      .switch {
        margin: 20px auto;
        display: flex;
        width: 134px;
        height: 32px;
        padding: 2px;
        box-sizing: border-box;
        font-size: 16px;
        border-radius: 32px;
        background-color: #383538;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.done {
          .btn {
            order: 0;
          }

          .val {
            order: 1;
          }
        }

        .btn {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background-color: #C556C2;
          order: 1;
        }

        .val {
          flex: 1 1;
          text-align: center;
          order: 0;
          color: #fff;
        }
      }

      .info {
        margin-top: 30px;
        background: #fff;
        padding: 30px 20px 20px;
        border-radius: 20px;
        .flex {
          display: flex;
          color: #000;
          line-height: 1;
          margin-bottom: 25px;
          font-size: 16px;
          align-items: center;

          &:last-of-type {
            margin-bottom: 0;

          }
          .ipt {
            flex: 1 1 0;
            height: 30px;
            border: none;
            border-bottom: 1px solid #C556C2;
            outline: none;
          }

          .sp1 {
            //width: 60px;
            margin-right: 8px;
          }

          .sp3 {
            margin-left: auto;
            width: 63px;
            height: 31px;
            border-radius: 37px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 16px;
            cursor: pointer;
            background-color: #880D85;
            &:hover {
              background-color: #CA0FC5;
            }
          }
        }
      }

      .action {
        margin-top: 30px;
        text-align: center;
        font-size: 0;
        display: flex;
        justify-content: space-between;

        .btn {
          width: 145px;
          height: 50px;
          font-size: 20px;
          border-radius: 50px;
          background-color: #4B9629;
          color: #fff;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover {
            background-color: #60B738;
          }
        }
      }
    }

  }

}

@media (max-width: 768px) {

  .ming {
    background: #fff;
    padding: 35px 25px;
    //padding-top: 40px;
    .container {
      text-align: center;
    }

    .tit {
      padding-top: 0;
      width: 160px;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }

    .sub-tit {
      font-size: 12px;
      margin-bottom: 20px;
    }

    .farm-wrapper {
      .farm-tit {
        text-align: left;
        line-height: 18px;
        font-size: 16px;
        margin-bottom: 13px;
      }
      .farm-table {
        padding: 0 15px;
        .tr {
          padding: 15px 0;
        }
        .t0, .t1, .t2, .t3, .t4, .t5 {
          font-size: 14px;
          line-height: 1.5;
        }
        .t0 {
          margin-left: 8px;
        }
      }
    }
    //.farm1 {
    //  .c1 { flex: 0 0 45%;}
    //  .c2 { flex: 0 0 20%;}
    //  .c3 { flex: 0 0 20%;}
    //  .c5 { flex: 0 0 15%;}
    //  .img-box {
    //    width: 30px;
    //    height: 30px;
    //    span {
    //      width: 15px;
    //      height: 15px;
    //      right: -5px;
    //    }
    //    img {
    //      width: inherit;
    //      height: inherit;
    //    }
    //  }
    //}
  }
  .m-farm2 {
    margin-top: 20px;
    .farm-tit {
      text-align: left;
      line-height: 18px;
      font-size: 16px;
      margin-bottom: 13px;
      color: #40153E;
      font-weight: bold;
    }
    .farm-list-item {
      box-shadow: 0 0 10px #c695bc;
      border-radius: 14px;
      position: relative;
      overflow: hidden;
      //padding: 0 15px;
      margin-bottom: 20px;
      .tip {
        position: absolute;
        top: 5px;
        left: 20px;
        color: rgb(240, 7, 28);
        font-size: 12px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 5px;
        border-radius: 14px;
        background-color: #880D85;
      }
      .item1, .item2 {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
      }
      .item1 {
        line-height: 18px;
        padding: 20px 15px 10px;
        .i1, .i2 {display: inline-flex; align-items: center}
        .i1 {
          .txt {
            font-size: 12px;
          }
        }
        .i2 {
          margin-left: 20px;

          .txt {
            font-size: 10px;
          }
        }
        .i3 {
          margin-left: auto;
          text-align: center;
          .t1 {
            font-size: 12px;
          }
          .t2 {
            color: #880D85;
          }
        }
        .txt {
          margin-left: 4px;
        }
        // partner apr
        .img-box {
          position: relative;

          .bnb-span {
            width: 15px;
            height: 15px;
            right: -5px;
            position: absolute;
            bottom: 0;
            border-radius: 50%;
            background: url('../assets/images/farm/i1.png') center center;
            background-size: 100% 100%;
          }

          .usdt-span {
            width: 15px;
            height: 15px;
            right: -5px;
            position: absolute;
            bottom: 0;
            border-radius: 50%;
            background: url('../assets/images/farm/i3.png') center center;
            background-size: 100% 100%;
          }

          // span {
          //   width: 15px;
          //   height: 15px;
          //   right: -5px;
          //   position: absolute;
          //   bottom: 0;
          //   border-radius: 50%;
          //   background: url('../assets/images/farm/i1.png') center center;
          //   background-size: 100% 100%;
          // }
        }
      }
      .item2 {
        padding: 15px;
        background-color: #f1e7f0;
        justify-content: space-between;
        color: #3C103A;
        .btn {
          width: 115px;
          height: 32px;
          background-color: #880D85;
          border: none;
          font-size: 12px;
        }
      }
    }
  }
  .m-farm1 {
    .farm-list-item {
      color: #3C103A;
      .item1 {
        .item1 {
          flex: 0 0 50%;
          * {
            font-size: 14px;
          }
        }
        .i3 {
          margin-left: 20px;
          &:first-of-type {
            margin-left: 0;
          }
          * {
            font-size: 12px;
          }
        }
        .i4 {
          margin-left: auto;
        }
      }
      .item2 {
        padding-left: 25px;
        .txt *{
          text-align: left;
          font-size: 12px;
          font-weight: bold;
        }
        .num {
          font-size: 22px;
        }
        .btn {width: 105px;}
      }
      .item3 {
        border-top: 1px solid #CAB1C9;
        padding: 20px 15px 20px 25px;
        background-color: #f1e7f0;
        font-weight: bold;
        .unlock-1 {
          .tit {
            font-size: 12px;
            line-height: 14px;
            margin-left: 0;
            color: #3C103A;
            margin-bottom: 5px;
            text-align: left;
          }
          .btn {
            width: 100%;
            height: 32px;
            font-size: 12px;
            background-color: #880D85;
            border: none;
          }
        }
        .lock {
          .txt *{
            text-align: left;
            font-size: 12px;
            font-weight: bold;
          }
          .num {
            font-size: 22px;
          }
          .btn {
            width: 36px;
            height: 36px;
            border-radius: 9px;
            border: none;
          }
          .btn-sub {
            margin-right: 10px;
            &:disabled {
              background-color: #B79AB6;
            }
          }
          .btn-add {
            background-color: #880D85;
          }
        }
      }
      .item4 {
        padding: 0 25px;
        background-color: #FFFAFF;
        .ii {
          display: flex;
          justify-content: space-between;
          padding: 10px 0;
          border-bottom: 1px solid #CAB1C9;
          &:last-of-type {
            border-bottom: none;
            padding-bottom: 20px;
            a {
              text-decoration: underline;
              color: #880D85;
            }
          }
          * {
            font-size: 14px;
          }
          &:nth-of-type(1) {
            a span {
              color: #58E419;
              margin-right: 8px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
